export default function NavBar() {
  return (
    <div className="h-[60px] border-b-[1px] border-b-[#000] border-opacity-10 flex shadow-lg bg-[#fff] sticky top-0 z-[1000]">
      <div className="w-full max-w-[1000px] h-full mx-auto flex items-center px-4">
        <div className="w-[130px] flex-shrink-0">
          <img
            src="https://storage.googleapis.com/primesecure/sulamerica-logo.webp"
            className="w-auto h-auto my-auto"
            alt="Logo RM"
          />
        </div>
      </div>
    </div>
  );
}
