import React from "react";

export default function BannerSection({
  chipText,
  titleText,
  descriptionText,
  imageUrl,
}) {
  return (
    <div className="text-center p-6  rounded-lg ">
      <div className="inline-block bg-bluePrime text-white text-sm font-semibold py-1 px-3 rounded-full mb-4">
        {chipText}
      </div>
      <h1 className="text-4xl font-bold text-white mb-4">{titleText}</h1>
      <p className="text-xl text-white mb-6">{descriptionText}</p>
      {imageUrl && (
        <img
          className="mx-auto w-1/2 rounded-lg "
          src={imageUrl}
          alt={titleText}
          loading="lazy"
        />
      )}
    </div>
  );
}
