"use client";
import axios from "axios";
import { useEffect } from "react";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import LoadingAnimation from "./icons/loadingSvg";

const getUtmParams = () => {
  let params = {};
  let search = window.location.search.substring(1);
  if (search) {
    search.split("&").forEach((item) => {
      let data = item.split("=");
      params[data[0]] = decodeURIComponent(data[1]);
    });
  }
  return params;
};

export default function SimpleFormSection({ title, description, submit }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const utmParams = getUtmParams();
    setFormData((prevFormData) => ({ ...prevFormData, ...utmParams }));
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    return formData.name && formData.email && formData.phone;
  };

  const handleButtonClick = async () => {
    if (validateForm()) {
      setIsLoading(true);

      // Armazena os dados no sessionStorage
      sessionStorage.setItem("formData", JSON.stringify(formData));

      const apiKey = process.env.REACT_APP_API_KEY_RD_STATION;
      const optionsRD = {
        method: "POST",
        url: `https://api.rd.services/platform/conversions?api_key=${apiKey}`,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        data: {
          event_type: "CONVERSION",
          event_family: "CDP",
          payload: {
            conversion_identifier: "lead-sulamerica-odonto-api-lp",
            email: formData.email,
            name: formData.name,
            mobile_phone: formData.phone,
            cf_utm_source: formData.utm_source,
            cf_utm_medium: formData.utm_medium,
            cf_utm_campaign: formData.utm_campaign,
          },
        },
      };

      try {
        const response = await axios(optionsRD);
        console.log("RD Station Response:", response);

        // Adicione o evento ao Data Layer
        window.dataLayer.push({
          event: "lead-saudesulamerica",
          formType: "Lead Generation",
          leadEmail: formData.email,
          utmSource: formData.utm_source,
          utmMedium: formData.utm_medium,
          utmCampaign: formData.utm_campaign,
        });

        navigate("/obrigado");
      } catch (error) {
        console.error("Erro na API RD Station:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg sm:mx-20">
      <div className="mb-4">
        <label className="font-semibold text-gray-900">Nome Completo</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Nome Completo"
          onChange={handleInputChange}
          className="w-full mt-2.5 p-2.5 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="font-semibold text-gray-900">E-mail</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="E-mail"
          className="w-full mt-2.5 p-2.5 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="font-semibold text-gray-900">Telefone</label>
        <InputMask
          mask="(99) 99999-9999"
          maskChar={null}
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="Telefone"
          className="w-full mt-2.5 p-2.5 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <button
        type="button"
        onClick={handleButtonClick}
        className="w-full bg-bluePrime text-white font-bold py-2.5 px-4 rounded-md flex justify-center items-center"
      >
        {isLoading ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 24c-1.657 0-3.156-.672-4.242-1.758l-2.828 2.828C7.77 25.748 9.81 26.746 12 26.746V24z"
            />
          </svg>
        ) : (
          "Cotar Agora"
        )}
      </button>
    </div>
  );
}

function InputField({ label, type = "text", name, value, onChange }) {
  return (
    <div>
      <label className="font-semibold text-gray-900">{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full mt-2.5 p-2.5 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
}
