import "./App.css";
import { Routes, Route } from "react-router-dom";
import IndexOdonto from "./components/odonto";
import ErrorBoundary from "./ErrorBoundary";
import NavBar from "./components/global/NavBar";
import Footer from "./components/global/Footer";
import PaginaObrigadoLp from "./components/global/PaginaObrigadoLp";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<IndexOdonto />} />
        <Route path="/obrigado" element={<PaginaObrigadoLp />} />
      </Routes>
      <Footer />
      <ErrorBoundary />
    </div>
  );
}

export default App;
