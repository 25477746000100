import { Helmet } from "react-helmet";

import SessaoInformativa from "../global/SessaoInformativa";
import InformacoesProdutos from "../global/ModuleInformacoesProdutos";
import FormOdontoBanner from "./FormOdontoBanner";
import ConteudoSessaoInfo from "../global/ConteudoSessaoInfo";
import sessaoInfoLp from "../global/SessaoInfoLp";
import FaqOdonto from "./Faq";

export default function IndexOdonto() {
  return (
    <div>
      <Helmet>
        <title>Plano de Saúde Odontológico | SulAmérica</title>
        <meta
          name="description"
          content="Tenha tranquilidade e cuidado completo com seu sorriso por meio do nosso seguro odontológico."
        />
        <meta
          name="keywords"
          content="Prime Secure, Seguros, Insurance, Insurtech, Corretora de Seguros, MarketPlace de Seguros, Odonto, Plano Odonto, Plano Odontologico, Seguros, SulAmérica"
        />
        <meta property="og:title" content="Plano Odontológico SulAmérica" />
        <meta
          property="og:description"
          content="Tenha tranquilidade e cuidado completo com seu sorriso por meio do nosso seguro odontológico."
        />
        <meta
          property="og:image"
          content="https://banco-de-imagens-webapp-primesecure.s3.sa-east-1.amazonaws.com/social-odonto-by-primesecure.png"
        />
        <meta
          property="og:url"
          content="https://www.odontosulamerica.com.br/"
        />
        <link rel="canonical" href="https://www.odontosulamerica.com.br/" />
      </Helmet>
      <FormOdontoBanner />
      <SessaoInformativa
        InformacoesProdutos={InformacoesProdutos}
        productId="5"
      />
      {/*<SimpleFormSection />*/}
      <ConteudoSessaoInfo sessaoInfoLp={sessaoInfoLp} sessaoInfoId="5" />
      <FaqOdonto />
    </div>
  );
}
